import React, { useEffect, useState } from "react"
import * as styles from "./TotalSupplyChart.module.scss"

export default function TotalSupplyChart() {
  const [activeElementInfo, setActiveElementInfo] = useState({})
  const sizeIncrease = 1

  useEffect(() => {
    setActiveElementInfoToDefault()
  }, [])

  const setActiveElementInfoToDefault = () => {
    console.log("render")
    setActiveElementInfo({
      title: "Public Sale",
      subTitle: "Round 1",
      percentage: "2%",
      rotateTarget: "310deg",
    })
  }

  const getElements = () => {
    const elements = [
      {
        name: "InnerCircle",
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "38",
              top: "47",
              transform: `rotate(${activeElementInfo.rotateTarget})`,
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="286.019"
            height="285.467"
            viewBox="0 0 286.019 285.467"
          >
            <g
              id="Group_68130"
              data-name="Group 68130"
              transform="translate(-37.49 -47.267)"
            >
              <path
                id="Union_2794"
                data-name="Union 2794"
                d="M110,3A108.294,108.294,0,0,0,88.43,5.164,106.979,106.979,0,0,0,11.405,68.051,105.581,105.581,0,0,0,5.173,88.036a107.076,107.076,0,0,0,0,42.928,105.636,105.636,0,0,0,16.1,38.074,107.178,107.178,0,0,0,187.326-18.089,105.581,105.581,0,0,0,6.232-19.985,107.076,107.076,0,0,0,0-42.928,105.637,105.637,0,0,0-16.1-38.074A107.011,107.011,0,0,0,110,3m0-3c60.751,0,110,49.025,110,109.5S170.751,219,110,219,0,169.975,0,109.5,49.249,0,110,0Z"
                transform="translate(37.49 129.68) rotate(-22)"
                fill="#8688a2"
                opacity="0.3"
              />
              <path
                id="Polygon_323"
                data-name="Polygon 323"
                d="M8.917,2.9a2,2,0,0,1,3.439,0l7.123,12a2,2,0,0,1-1.72,3.021H3.513a2,2,0,0,1-1.72-3.021Z"
                transform="translate(169.863 63.554)"
                fill="#8688a2"
                opacity="0.3"
              />
            </g>
          </svg>
        ),
      },

      {
        name: "In Game",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "9.53",
              top: "137.48",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={272.052 * sizeIncrease}
            height={223.517 * sizeIncrease}
            viewBox={`0 0 ${272.052 * sizeIncrease} ${223.517 * sizeIncrease}`}
          >
            <path
              onMouseEnter={() =>
                setActiveElementInfo({
                  title: "In Game",
                  percentage: "40%",
                  rotateTarget: "585deg",
                })
              }
              onMouseLeave={setActiveElementInfoToDefault}
              id="Path_36116"
              data-name="Path 36116"
              d="M136.514,338.473A170.911,170.911,0,0,1,3.474,205.431a172.614,172.614,0,0,1,0-68.913,169.815,169.815,0,0,1,4.754-18.092l28.957,9.651,1.261.889A139.026,139.026,0,0,0,32,171c0,76.645,62.356,139,139,139a137.945,137.945,0,0,0,81.786-26.6l.337.464c.055-.178.127-.409.213-.688l18.716,25.694q-2.709,1.99-5.489,3.868a171.466,171.466,0,0,1-130.048,25.727Z"
              transform="translate(0 -118.426)"
              fill="#ae3796"
            />
          </svg>
        ),
      },

      {
        name: "Partnerships",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "17.77",
              top: "88.56",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={50.365 * sizeIncrease}
            height={59.818 * sizeIncrease}
            viewBox={`0 0 ${50.365 * sizeIncrease} ${59.818 * sizeIncrease}`}
          >
            <path
              onMouseEnter={() =>
                setActiveElementInfo({
                  title: "Partnerships",
                  percentage: "10%",
                  rotateTarget: "296deg",
                })
              }
              onMouseLeave={setActiveElementInfoToDefault}
              id="Path_36117"
              data-name="Path 36117"
              d="M8.237,118.4q2.293-7.1,5.2-13.978A170.99,170.99,0,0,1,29.2,75.384q2.018-2.98,4.159-5.88l18.13,14.153-.019-.015L58.6,89.21a138.233,138.233,0,0,0-20.266,40.112Z"
              transform="translate(-8.237 -69.504)"
              fill="#fac63b"
            />
          </svg>
        ),
      },

      {
        name: "Treasury",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "262.52",
              top: "138.21",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={88.952 * sizeIncrease}
            height={190.116 * sizeIncrease}
            viewBox={`0 0 ${88.952 * sizeIncrease} ${190.116 * sizeIncrease}`}
          >
            <path
              onMouseEnter={() =>
                setActiveElementInfo({
                  title: "Treasury",
                  percentage: "20%",
                  rotateTarget: "470deg",
                })
              }
              onMouseLeave={setActiveElementInfoToDefault}
              id="Path_36119"
              data-name="Path 36119"
              d="M252.991,284.3l.277-.9.114-.366.007-.024.02-.064A139.081,139.081,0,0,0,303.517,129l30.431-9.846a169.919,169.919,0,0,1,4.522,17.358,172.65,172.65,0,0,1,0,68.913,170.063,170.063,0,0,1-25.727,61.135,171.863,171.863,0,0,1-41.235,42.71Z"
              transform="translate(-252.991 -119.158)"
              fill="#ff672a"
            />
          </svg>
        ),
      },

      {
        name: "Liquidity Pools",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "180.77",
              top: "19.06",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={101.667 * sizeIncrease}
            height={60.189 * sizeIncrease}
            viewBox={`0 0 ${101.667 * sizeIncrease} ${60.189 * sizeIncrease}`}
          >
            <path
              onMouseEnter={() =>
                setActiveElementInfo({
                  title: "Liquidity Pools",
                  percentage: "10%",
                  rotateTarget: "378deg",
                })
              }
              onMouseLeave={setActiveElementInfoToDefault}
              id="Path_36115"
              data-name="Path 36115"
              d="M171.239,32.005V0a172.2,172.2,0,0,1,34.189,3.473A170.086,170.086,0,0,1,266.563,29.2q3.218,2.173,6.343,4.5l-18.078,26.49a138.271,138.271,0,0,0-83.589-28.189"
              transform="translate(-171.239 -0.005)"
              fill="#69a34d"
            />
          </svg>
        ),
      },

      {
        name: "Private Sale",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "264.36",
              top: "52.73",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={79.147 * sizeIncrease}
            height={96.46 * sizeIncrease}
            viewBox={`0 0 ${79.147 * sizeIncrease} ${96.46 * sizeIncrease}`}
          >
            <path
              onMouseEnter={() =>
                setActiveElementInfo({
                  title: "Private Sale",
                  percentage: "10%",
                  rotateTarget: "415deg",
                })
              }
              onMouseLeave={setActiveElementInfoToDefault}
              id="Path_36118"
              data-name="Path 36118"
              d="M254.906,60.069l18.058-26.393C298.282,52,316.312,75.649,328.5,104.427q3.075,7.271,5.471,14.829l-30.105,10.88c-8.827-28.49-25.022-52.256-49.042-69.938"
              transform="translate(-254.828 -33.676)"
              fill="#359ae7"
            />
          </svg>
        ),
      },
      {
        name: "public Sale Container",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "42.86",
              top: "19",
              zIndex: 5,
              opacity: 1,
              pointerEvents: "none",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={138.638 * sizeIncrease}
            height={89.124 * sizeIncrease}
            viewBox={`0 0 ${138.638 * sizeIncrease} ${89.124 * sizeIncrease}`}
          >
            <g
              id="Group_68131"
              data-name="Group 68131"
              transform="translate(-31.862 -7.003)"
            >
              <rect
                id="Rectangle_148185"
                data-name="Rectangle 148185"
                width="4"
                height="26"
                transform="translate(66.673 45.411) rotate(-41)"
                fill="#21427d"
              />
              <g
                id="Path_36086"
                data-name="Path 36086"
                transform="translate(-1.338 7.003)"
                fill="none"
              >
                <path
                  d="M51.469,83.641h0L33.2,69.72A170.565,170.565,0,0,1,170.971,0h.867V32H171A139.574,139.574,0,0,0,58.665,89.124Z"
                  stroke="none"
                />
                <path
                  d="M 57.89266967773438 83.50670623779297 C 84.25059509277344 49.5919303894043 124.8716125488281 29.08349800109863 167.8379974365234 28.04145622253418 L 167.8379974365234 4.028563499450684 C 157.5984497070312 4.217830657958984 147.3456115722656 5.346963405609131 137.3175964355469 7.391646862030029 C 126.6007080078125 9.586668014526367 116.056999206543 12.85954284667969 105.9827041625977 17.11795997619629 C 79.49945068359375 28.34068298339844 56.34222412109375 46.21848297119141 38.78876495361328 68.94969177246094 L 57.89266967773438 83.50670623779297 M 58.66499710083008 89.12400054931641 L 33.19999694824219 69.72000122070312 C 51.45099639892578 44.89900207519531 76.05500030517578 25.45600128173828 104.4219970703125 13.43500137329102 C 114.7649993896484 9.063000679016113 125.5149993896484 5.726001262664795 136.5149993896484 3.473001003265381 C 147.8539886474609 1.161002397537231 159.3995208740234 -0.003000218886882067 170.9709930419922 1.098632765206276e-06 L 171.8379974365234 1.098632765206276e-06 L 171.8379974365234 32 L 171 32 C 126.6009979248047 32.09000015258789 84.89299774169922 53.29899978637695 58.66499710083008 89.12400054931641 Z"
                  stroke="none"
                  fill="#21427d"
                />
              </g>
              <rect
                id="Rectangle_148186"
                data-name="Rectangle 148186"
                width="4"
                height="27.076"
                transform="matrix(0.669, -0.743, 0.743, 0.669, 45.83, 63.404)"
                fill="#21427d"
              />
            </g>
          </svg>
        ),
      },

      {
        name: "Round 1",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "47.33",
              top: "75.38",
              zIndex: 2,
              opacity: 1,
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={31.659 * sizeIncrease}
            height={29.129 * sizeIncrease}
            viewBox={`0 0 ${31.659 * sizeIncrease} ${29.129 * sizeIncrease}`}
          >
            <path
              onMouseEnter={() =>
                setActiveElementInfo({
                  title: "Public Sale",
                  subTitle: "Round 1",
                  percentage: "2%",
                  rotateTarget: "300deg",
                })
              }
              onMouseLeave={setActiveElementInfoToDefault}
              id="Intersection_9"
              data-name="Intersection 9"
              d="M6.008,68q4.9-6.371,10.361-12.229l21.3,18.3A140.319,140.319,0,0,0,28.355,84.9Z"
              transform="translate(-6.008 -55.769)"
              fill="#4fddf3"
            />
          </svg>
        ),
      },
      {
        name: "Round 2",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "56.61",
              top: "56.62",
              zIndex: 2,
              opacity: 1,
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={39.553 * sizeIncrease}
            height={37.236 * sizeIncrease}
            viewBox={`0 0 ${39.553 * sizeIncrease} ${37.236 * sizeIncrease}`}
          >
            <path
              onMouseEnter={() =>
                setActiveElementInfo({
                  title: "Public Sale",
                  subTitle: "Round 2",
                  percentage: "5%",
                  rotateTarget: "318deg",
                })
              }
              onMouseLeave={setActiveElementInfoToDefault}
              id="Intersection_8"
              data-name="Intersection 8"
              d="M14.078,55.855a169.575,169.575,0,0,1,20.7-18.846L53.631,58A141.491,141.491,0,0,0,35.287,74.244Z"
              transform="translate(-14.078 -37.009)"
              fill="#f4e63b"
            />
          </svg>
        ),
      },
      {
        name: "Round 3",
        percentage: 40,
        SVGElement: (
          <svg
            className={styles.element}
            style={{
              left: "46.71",
              top: "22.62",
              zIndex: 1,
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={131.453 * sizeIncrease}
            height={81.892 * sizeIncrease}
            viewBox={`0 0 ${131.453 * sizeIncrease} ${81.892 * sizeIncrease}`}
          >
            <path
              onMouseEnter={() =>
                setActiveElementInfo({
                  title: "Public Sale",
                  subTitle: "Round 3",
                  percentage: "10%",
                  rotateTarget: "335deg",
                })
              }
              onMouseLeave={setActiveElementInfoToDefault}
              id="Intersection_10"
              data-name="Intersection 10"
              d="M24.877,84.9l-4.79-3.65L4.184,69.137A167.528,167.528,0,0,1,135.638,3.013V29.021A143.137,143.137,0,0,0,24.877,84.9Z"
              transform="translate(-4.185 -3.013)"
              fill="#f4b03b"
            />
          </svg>
        ),
      },
    ]
    return elements
  }
  const elements = getElements()

  return (
    <div className={styles.container}>
      {elements.map(el => {
        return el.SVGElement
      })}
      <div className={styles.infoContainer}>
        <h3>{activeElementInfo.title}</h3>
        {activeElementInfo.subTitle ? (
          <h4>{activeElementInfo.subTitle}</h4>
        ) : null}
        <p>{activeElementInfo.percentage}</p>
      </div>
    </div>
  )
}
